<template>
  <div class="coupons">
    
    <!-- Crear promociones -->
    <div class="new-promotions">
        <h2 class="section-title font-weight-black">Crear cupón</h2>
        <v-form
            ref="formCampaign"
            v-model="validFormCampaign"
            lazy-validation>
            <v-row>
                <v-col cols="12" md="4">
                    <v-select 
                        :items="campaigns" 
                        v-model="newPromotion.campaign"
                        :rules="[rules.normalRequired]" 
                        label="Selecciona una campaña">
                    </v-select>
                </v-col>
            </v-row>
        </v-form>
        <v-btn class="action-buttons" tile color="primary" dark @click="openNewPromotion">CREAR CUPÓN</v-btn>
    </div>

    <!-- Consumir cupones -->
    <div class="consume-coupons">
        <h2 class="section-title font-weight-black">Consumir cupones Tchin Tchin</h2>
        <p>Introduce el número del cupón promocional Tchin Tchin para acceder a la ficha detalle del mismo y consumir el cupón.</p>
        <v-form
            ref="formCoupon"
            v-model="validFormCoupon"
            lazy-validation>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Número de cupón"
                        v-model="couponCode"
                        :rules="[rules.normalRequired]">
                    </v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-btn class="action-buttons" tile color="primary" dark @click="getCoupon">VER FICHA DE CUPÓN</v-btn>
    </div>

    <!-- Buscador cupones -->
    <div class="search-coupons">
        <h2 class="section-title font-weight-black">Buscador de cupones Tchin Tchin</h2>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    single-line
                    outlined
                    flat
                    label="Buscar cupones por nº de cupón, padrino, ahijado o cód. tienda"
                    append-icon="mdi-magnify"
                    height="30px"
                    v-model="simpleFilter"
                    @keypress.native.enter="searchByFilters">
                </v-text-field>
                <p class="font-weight-black">{{ coupons.length }} resultados encontrados</p>
            </v-col>
            <v-col cols="12" md="4">
                <v-menu
                    left tile 
                    offset-y 
                    v-model="menuFilters"
                    :close-on-content-click=false>
                    <template v-slot:activator="{ on }">
                        <v-btn class="filter-button" tile color="#E7E8EB" v-on="on">
                            <v-icon left>mdi-filter-variant</v-icon> FILTROS
                        </v-btn>
                    </template>
                    <v-card>
                        <v-row class="filters-popup">
                            <v-col cols="12" md="4">
                                <p>Estado</p>
                                <v-checkbox v-model="filters.sales_type" label="Consumido" value="Consumido"></v-checkbox>
                                <v-checkbox v-model="filters.sales_type" label="Vigente" value="Vigente"></v-checkbox>
                                <v-checkbox v-model="filters.sales_type" label="Caducado" value="Caducado"></v-checkbox>
                            </v-col>
                            <!--<v-col cols="12" md="3">
                            </v-col>-->
                            <v-col cols="12" md="4">
                                <p>Creado</p>
                                <v-menu
                                    ref="menuCreatedDateSince"
                                    v-model="menuCreatedDateSince"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterCreatedDateSince"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.created_date.since"
                                            label="Desde"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.created_date.since" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuCreatedDateSince = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuCreatedDateSince.save(filterCreatedDateSince)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    ref="menuCreatedDateUntil"
                                    v-model="menuCreatedDateUntil"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterCreatedDateUntil"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.created_date.until"
                                            label="Hasta"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.created_date.until" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuCreatedDateUntil = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuCreatedDateUntil.save(filterCreatedDateUntil)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4">
                                <p>Consumido</p>
                                <v-menu
                                    ref="menuConsumedDateSince"
                                    v-model="menuConsumedDateSince"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterConsumedDateSince"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.consumed_date.since"
                                            label="Desde"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.consumed_date.since" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuConsumedDateSince = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuConsumedDateSince.save(filterConsumedDateSince)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    ref="menuConsumedDateUntil"
                                    v-model="menuConsumedDateUntil"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterConsumedDateUntil"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.consumed_date.until"
                                            label="Hasta"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.consumed_date.until" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuConsumedDateUntil = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuConsumedDateUntil.save(filterConsumedDateUntil)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="clearFilters">LIMPIAR FILTROS</v-btn>
                            <v-btn color="primary" text @click="searchByFilters">APLICAR FILTROS</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn rounded outlined color="primary" dense class="ma-2" @click="csvExport()">
                    <v-icon>mdi-download</v-icon> DESCARGAR EXCEL
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="coupons"
            :items-per-page="5"
            :loading="loadingCoupons"
            class="coupons-table">
        </v-data-table>
    </div>

    <v-dialog v-model="newPromotionDialog" max-width="1300px">
        <v-card>
            <v-card-title class="headline lighten-2">
                Crear promoción
            </v-card-title>

            <v-row class="justify-center">
                <v-col cols="12" md="4" align="center">
                    <div class="step1">
                        <v-btn color="primary" fab x-large dark @click="toStep1">
                            <v-icon x-large>mdi-checkbox-marked-circle-outline</v-icon>
                        </v-btn>
                        <br/><br/>
                        Datos del Padrino
                    </div>
                </v-col>
                <v-col cols="12" md="4" align="center" v-if="showStep2">
                    <div class="step2" v-if="activeStep2">
                        <v-btn color="primary" fab x-large dark @click="toStep2">
                            <v-icon x-large>mdi-checkbox-marked-circle-outline</v-icon>
                        </v-btn>
                        <br/><br/>
                        Datos del Ahijado
                    </div>
                    <div class="step2" v-if="!activeStep2">
                        <v-btn color="grey lighten-1" fab x-large dark @click="toStep2">
                            <v-icon x-large>mdi-checkbox-marked-circle-outline</v-icon>
                        </v-btn>
                        <br/><br/>
                        Datos del Ahijado
                    </div>
                </v-col>
                <v-col cols="12" md="4" align="center" v-if="showStep3">
                    <div class="step3" v-if="activeStep3">
                        <v-btn color="primary" fab x-large dark @click="toStep3">
                            <v-icon x-large>mdi-checkbox-marked-circle-outline</v-icon>
                        </v-btn>
                        <br/><br/>
                        Consumir promoción
                    </div>
                    <div class="step2" v-if="!activeStep3">
                        <v-btn color="grey lighten-1" fab x-large dark @click="toStep3">
                            <v-icon x-large>mdi-checkbox-marked-circle-outline</v-icon>
                        </v-btn>
                        <br/><br/>
                        Consumir promoción
                    </div>
                </v-col>
            </v-row>

            
                <!-- DATOS PADRINO -->
                <v-form
                ref="formStep1"
                v-model="validFormStep1"
                lazy-validation 
                v-if="currentStep === '1'">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-switch class="v-input--reverse" v-model="instantConsume" @click="changeStep3">
                                    <template #label>
                                        ¿Se consume la promoción en el momento?
                                    </template>
                                </v-switch>
                                <v-checkbox label="No da datos del ahijado" v-model="avoidStep2" :disabled="sameStep2" @click="changeStep2"></v-checkbox>
                                <v-checkbox label="El ahijado coincide con el padrino" v-model="sameStep2" :disabled="avoidStep2" @click="changeStep2"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Importe de la venta *"
                                    v-model="newPromotion.sale_amount"
                                    :rules="[rules.normalRequired]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h3>Datos personales</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Nombre *"
                                    :rules="[rules.normalRequired]"
                                    v-model="newPromotion.godfather_firstname">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Primer apellido *"
                                    :rules="[rules.normalRequired]"
                                    v-model="newPromotion.godfather_lastname1">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Segundo apellido *"
                                    :rules="[rules.normalRequired]"
                                    v-model="newPromotion.godfather_lastname2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="newPromotion.godfather_birthdate"
                                            label="Fecha de nacimiento"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="newPromotion.godfather_birthdate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <!--<v-checkbox label="Edad aproximada" v-model="newPromotion.aprox_age"></v-checkbox>-->
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="DNI/NIE"
                                    :rules="[rules.validateDNI]"
                                    v-model="newPromotion.godfather_nif">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                Firmada RGPD *
                                <v-radio-group row :rules="[rules.normalRequired]" v-model="newPromotion.godfather_rgpd">
                                    <v-radio label="Sí" value="true"></v-radio>
                                    <v-radio label="No" value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="6">
                                Género *
                                <v-radio-group row :rules="[rules.normalRequired]" v-model="newPromotion.godfather_gender">
                                    <v-radio label="Hombre" value="Hombre"></v-radio>
                                    <v-radio label="Mujer" value="Mujer"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h3>Datos de contacto</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Email *"
                                    :rules="[rules.normalRequired]"
                                    v-model="newPromotion.godfather_email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Móvil"
                                    v-model="newPromotion.godfather_mobile">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Dirección"
                                    append-icon="mdi-map-marker"
                                    v-model="newPromotion.godfather_address">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="CP"
                                    v-model="newPromotion.godfather_zip">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Población"
                                    v-model="newPromotion.godfather_city">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                Provincia
                                <v-select 
                                    :items="states" 
                                    label="Selecciona una provincia"
                                    v-model="newPromotion.godfather_state">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                País
                                <v-select 
                                    :items="countries" 
                                    label="Selecciona un país"
                                    v-model="newPromotion.godfather_country">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h3>Datos RGPD</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-checkbox label="Comunicaciones comerciales Alain Afflelou"
                                    v-model="newPromotion.godfather_communications"></v-checkbox>
                                <v-checkbox label="Cesión otras ópticas"
                                    v-model="newPromotion.godfather_cesion"></v-checkbox>
                                <v-checkbox label="Cesión Fundación Alain Afflelou"
                                    v-model="newPromotion.godfather_cesion_foundation"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
                <!-- DATOS PADRINO -->

                <!-- DATOS AHIJADO -->
                <v-form
                ref="formStep2"
                v-model="validFormStep2"
                lazy-validation 
                v-if="currentStep === '2'">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-switch class="v-input--reverse" v-model="instantConsume" @click="changeStep3">
                                    <template #label>
                                        ¿Se consume la promoción en el momento?
                                    </template>
                                </v-switch>
                                <v-checkbox label="No da datos del ahijado" v-model="avoidStep2" :disabled="sameStep2" @click="changeStep2"></v-checkbox>
                                <v-checkbox label="El ahijado coincide con el padrino" v-model="sameStep2" :disabled="avoidStep2" @click="changeStep2"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h3 class="text--disabled" v-if="avoidStep2 || sameStep2">Datos personales</h3>
                                <h3 v-if="!avoidStep2 && !sameStep2">Datos personales</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Nombre *"
                                    :rules="[rules.disabledRequired]"
                                    v-model="newPromotion.godchild_firstname"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Primer apellido *"
                                    :rules="[rules.disabledRequired]"
                                    v-model="newPromotion.godchild_lastname1"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Segundo apellido *"
                                    :rules="[rules.disabledRequired]"
                                    v-model="newPromotion.godchild_lastname2"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="newPromotion.godchild_birthdate"
                                            label="Fecha de nacimiento"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="avoidStep2 || sameStep2">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="newPromotion.godchild_birthdate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <!--<v-checkbox label="Edad aproximada" v-model="newPromotion.aprox_age" disabled></v-checkbox>-->
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="DNI/NIE"
                                    v-model="newPromotion.godchild_nif"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <p class="text--disabled" v-if="avoidStep2 || sameStep2">Firmada RGPD *</p>
                                <p v-if="!avoidStep2 && !sameStep2">Firmada RGPD *</p>
                                <v-radio-group row 
                                    :rules="[rules.disabledRequired]" 
                                    v-model="newPromotion.godchild_rgpd" 
                                    :disabled="avoidStep2 || sameStep2">
                                    <v-radio label="Sí" value="true"></v-radio>
                                    <v-radio label="No" value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="text--disabled" v-if="avoidStep2 || sameStep2">Género *</p>
                                <p v-if="!avoidStep2 && !sameStep2">Género *</p>
                                <v-radio-group row :rules="[rules.disabledRequired]" v-model="newPromotion.godchild_gender" :disabled="avoidStep2 || sameStep2">
                                    <v-radio label="Hombre" value="Hombre"></v-radio>
                                    <v-radio label="Mujer" value="Mujer"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h3 class="text--disabled" v-if="avoidStep2 || sameStep2">Datos de contacto</h3>
                                <h3 v-if="!avoidStep2 && !sameStep2">Datos de contacto</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Email *"
                                    :rules="[rules.normalRequired]"
                                    v-model="newPromotion.godchild_email"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Móvil"
                                    v-model="newPromotion.godchild_mobile"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Dirección"
                                    append-icon="mdi-map-marker"
                                    v-model="newPromotion.godchild_address"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="CP"
                                    v-model="newPromotion.godchild_zip"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Población"
                                    v-model="newPromotion.godchild_city"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <p class="text--disabled" v-if="avoidStep2 || sameStep2">Provincia</p>
                                <p v-if="!avoidStep2 && !sameStep2">Provincia</p>
                                <v-select 
                                    :items="states" 
                                    label="Selecciona una provincia"
                                    v-model="newPromotion.godchild_state"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="text--disabled" v-if="avoidStep2 || sameStep2">País</p>
                                <p v-if="!avoidStep2 && !sameStep2">País</p>
                                <v-select 
                                    :items="countries" 
                                    label="Selecciona un país"
                                    v-model="newPromotion.godchild_country"
                                    :disabled="avoidStep2 || sameStep2">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h3 class="text--disabled" v-if="avoidStep2 || sameStep2">Datos RGPD</h3>
                                <h3 v-if="!avoidStep2 && !sameStep2">Datos RGPD</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-checkbox label="Comunicaciones comerciales Alain Afflelou"
                                    v-model="newPromotion.godchild_communications"
                                    :disabled="avoidStep2 || sameStep2"></v-checkbox>
                                <v-checkbox label="Cesión otras ópticas"
                                    v-model="newPromotion.godchild_cesion"
                                    :disabled="avoidStep2 || sameStep2"></v-checkbox>
                                <v-checkbox label="Cesión Fundación Alain Afflelou"
                                    v-model="newPromotion.godchild_cesion_foundation"
                                    :disabled="avoidStep2 || sameStep2"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
                <!-- DATOS AHIJADO -->

                <!-- CONSUMIR CUPÓN -->
                <v-card-text v-if="currentStep === '3'">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-switch class="v-input--reverse" v-model="instantConsume" @click="changeStep3">
                                <template #label>
                                    ¿Se consume la promoción en el momento?
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <!-- Datos Marketing -->
                    <h3>Datos Marketing</h3>
                    <v-row>
                        <v-col cols="12" md="12">
                            <span class="text--secondary">Promoción: </span> {{ this.newPromotion.campaign }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <span class="text--secondary">Tienda: </span> {{ this.newPromotion.store_code }}
                        </v-col>
                        <v-col cols="12" md="3">
                            <span class="text--secondary">Fecha Consumo: </span> {{ this.newPromotion.consumed_date }}
                        </v-col>
                        <v-col cols="12" md="3">
                            <span class="text--secondary">Fecha Creación: </span> {{ this.newPromotion.created_date }}
                        </v-col>
                        <v-col cols="12" md="3">
                            <span class="text--secondary">Fecha Validez: </span> {{ this.newPromotion.validation_date }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <span class="text--secondary">Importe de la venta padrino: </span> {{ this.newPromotion.sale_amount + ' €' }}
                        </v-col>
                    </v-row>
                    <!-- Datos Padrino / Ahijado -->
                    <v-row>
                        <v-col cols="12" md="6">
                            <!-- Datos Padrino -->
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Datos Padrino</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4>Datos Personales</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Nombre completo: </span> 
                                    {{ this.newPromotion.godfather_firstname + ' ' + this.newPromotion.godfather_lastname1 + ' ' + this.newPromotion.godfather_lastname2 }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Fecha de nacimiento: </span> {{ this.newPromotion.godfather_birthdate }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">DNI/NIE: </span> {{ this.newPromotion.godfather_nif }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Género: </span> {{ this.newPromotion.godfather_gender }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4>Datos Contacto</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Email: </span> {{ this.newPromotion.godfather_email }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Móvil: </span> {{ this.newPromotion.godfather_mobile }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Dirección: </span> {{ this.newPromotion.godfather_address }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">CP: </span> {{ this.newPromotion.godfather_zip }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Población: </span> {{ this.newPromotion.godfather_city }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Provincia: </span> {{ this.newPromotion.godfather_state }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">País: </span> {{ this.newPromotion.godfather_country }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4>Datos RGPD</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                <span class="text--secondary">Firmado RGPD: </span> {{ (this.newPromotion.godfather_rgpd == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Comunicaciones comerciales Alain Afflelou: </span> {{ (this.newPromotion.godfather_communications == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Cesión otras ópticas: </span> {{ (this.newPromotion.godfather_cesion == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--secondary">Cesión Fundación Alain Afflelou: </span> {{ (this.newPromotion.godfather_cesion_foundation == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <!-- Datos Ahijado -->
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3 class="text--disabled" v-if="avoidStep2">Datos Ahijado</h3>
                                    <h3 v-if="!avoidStep2">Datos Ahijado</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4 class="text--disabled" v-if="avoidStep2">Datos Personales</h4>
                                    <h4 v-if="!avoidStep2">Datos Personales</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Nombre completo: </span>
                                    <span class="text--secondary" v-if="!avoidStep2">Nombre completo: </span>
                                    {{ this.godchild_fullname }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Fecha de nacimiento: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Fecha de nacimiento: </span> 
                                    {{ this.newPromotion.godchild_birthdate }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">DNI/NIE: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">DNI/NIE: </span> 
                                    {{ this.newPromotion.godchild_nif }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Género: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Género: </span> 
                                    {{ this.newPromotion.godchild_gender }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4 class="text--disabled" v-if="avoidStep2">Datos Contacto</h4>
                                    <h4 v-if="!avoidStep2">Datos Contacto</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Email: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Email: </span> 
                                    {{ this.newPromotion.godchild_email }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Móvil: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Móvil: </span> 
                                    {{ this.newPromotion.godchild_mobile }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Dirección: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Dirección: </span> 
                                    {{ this.newPromotion.godchild_address }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">CP: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">CP: </span> 
                                    {{ this.newPromotion.godchild_zip }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Población: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Población: </span> 
                                    {{ this.newPromotion.godchild_city }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Provincia: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Provincia: </span> 
                                    {{ this.newPromotion.godchild_state }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">País: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">País: </span> 
                                    {{ this.newPromotion.godchild_country }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4 class="text--disabled" v-if="avoidStep2">Datos RGPD</h4>
                                    <h4 v-if="!avoidStep2">Datos RGPD</h4>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Firmado RGPD: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Firmado RGPD: </span> 
                                    {{ (this.newPromotion.godchild_rgpd == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Comunicaciones comerciales Alain Afflelou: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Comunicaciones comerciales Alain Afflelou: </span> 
                                    {{ (this.newPromotion.godchild_communications == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Cesión otras ópticas: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Cesión otras ópticas: </span> 
                                    {{ (this.newPromotion.godchild_cesion == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <span class="text--disabled" v-if="avoidStep2">Cesión Fundación Alain Afflelou: </span> 
                                    <span class="text--secondary" v-if="!avoidStep2">Cesión Fundación Alain Afflelou: </span> 
                                    {{ (this.newPromotion.godchild_cesion_foundation == 'true') ? 'Sí' : 'No' }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- CONSUMIR CUPÓN -->

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="cancelDialog">CANCELAR</v-btn>
                <!-- DATOS PADRINO -->
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '1' && showStep2 && !showStep3" @click="toStep2">DATOS AHIJADO</v-btn>
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '1' && showStep2 && showStep3" @click="toStep2">DATOS AHIJADO</v-btn>
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '1' && !showStep2 && showStep3" @click="toStep3">CONSUMIR CUPÓN</v-btn>
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '1' && !showStep2 && !showStep3" @click="saveNewPromotion">CREAR PROMOCIÓN</v-btn>
                <!-- DATOS PADRINO -->
                <!-- DATOS AHIJADO -->
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '2' && showStep3" @click="toStep3">CONSUMIR CUPÓN</v-btn>
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '2' && !showStep3" @click="saveNewPromotion">CREAR PROMOCIÓN</v-btn>
                <!-- DATOS AHIJADO -->
                <!-- CONSUMIR CUPÓN -->
                <v-btn class="action-buttons" tile color="primary" dark v-if="currentStep == '3'" @click="saveNewPromotion">CREAR Y CONSUMIR PROMOCIÓN</v-btn>
                <!-- CONSUMIR CUPÓN -->
            </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="error">
        {{ errorMessage }}
        <v-btn dark text
        @click="snackbar = false">
            Cerrar
        </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import store from '@/store/index.js'
export default {
    name: 'Coupons',
    data() {
        return {
            date: '', 
            couponCode: '', 
            simpleFilter: '', 
            loadingCoupons: false, 
            menuFilters: false, 
            newPromotionDialog: false, 
            menu: false,
            modal: false,
            snackbar: false, 
            errorMessage: '', 
            currentStep: '1', 
            instantConsume: false, 
            avoidStep2: false, 
            sameStep2: false, 
            showStep2: true, 
            activeStep2: false, 
            showStep3: false, 
            activeStep3: false, 
            validFormStep1: false, 
            validFormStep2: false, 
            validFormCampaign: false, 
            validFormCoupon: false, 
            menuCreatedDateUntil: false, 
            menuCreatedDateSince: false, 
            menuConsumedDateUntil: false, 
            menuConsumedDateSince: false, 
            filterCreatedDateSince: '', 
            filterCreatedDateUntil: '', 
            filterConsumedDateSince: '', 
            filterConsumedDateUntil: '', 
            newPromotion: { 
                id: '', 
                name: '', 
                campaign: '', 
                status: '', 
                store_code: '', 
                consumed_date: '', 
                created_date: '', 
                validation_date: '', 
                sale_amount: '', 
                godfather_firstname: '', 
                godfather_lastname1: '', 
                godfather_lastname2: '', 
                godfather_fullname: '', 
                godfather_birthdate: '', 
                godfather_aprox_age: '', 
                godfather_nif: '', 
                godfather_gender: '', 
                godfather_email: '', 
                godfather_mobile: '', 
                godfather_address: '', 
                godfather_zip: '', 
                godfather_city: '', 
                godfather_state: '', 
                godfather_country: '', 
                godfather_rgpd: '', 
                godfather_communications: '', 
                godfather_cesion: '', 
                godfather_cesion_foundation: '', 
                godchild_firstname: '', 
                godchild_lastname1: '', 
                godchild_lastname2: '', 
                godchild_fullname: '', 
                godchild_birthdate: '', 
                godchild_aprox_age: '', 
                godchild_nif: '', 
                godchild_gender: '', 
                godchild_email: '', 
                godchild_mobile: '', 
                godchild_address: '', 
                godchild_zip: '', 
                godchild_city: '', 
                godchild_state: '', 
                godchild_country: '', 
                godchild_rgpd: '', 
                godchild_communications: '', 
                godchild_cesion: '', 
                godchild_cesion_foundation: '' 
            }, 
            campaigns: [
                { text: 'Tchin Tchin Verano 2020', value: 'Tchin Tchin Verano 2020' }
            ], 
            coupons: [
                { id: '111111111111111111111111', name: '019405000352', campaign: 'Navidad 2020', status: 'Consumido', store_code: 'FAPP', consumed_date: '29/01/2020', created_date: '29/01/2020', validation_date: '', sale_amount: '', godfather_firstname: 'Fernández', godfather_lastname: 'Quintana', godfather_fullname: 'Fernández Quintana', godfather_birthdate: '', godfather_aprox_age: '', godfather_nif: '', godfather_gender: '', godfather_email: '', godfather_mobile: '', godfather_address: '', godfather_zip: '', godfather_city: '', godfather_state: '', godfather_country: '', godfather_rgpd: '', godfather_communications: '', godfather_cesion: '', godfather_cesion_foundation: '', godchild_firstname: 'Pardo', godchild_lastname: 'Soldevilla', godchild_fullname: 'Pardo Soldevilla', godchild_birthdate: '', godchild_aprox_age: '', godchild_nif: '', godchild_gender: '', godchild_email: '', godchild_mobile: '', godchild_address: '', godchild_zip: '', godchild_city: '', godchild_state: '', godchild_country: '', godchild_rgpd: '', godchild_communications: '', godchild_cesion: '', godchild_cesion_foundation: '' }, 
                { id: '222222222222222222222222', name: 'Producto jklm', campaign: 'Navidad 2020', status: 'Consumido', store_code: '2BBMA', consumed_date: '22/01/2020', created_date: '22/01/2020', validation_date: '', sale_amount: '', godfather_firstname: 'Quintana', godfather_lastname: 'Fernández', godfather_fullname: 'Quintana Fernández', godfather_birthdate: '', godfather_aprox_age: '', godfather_nif: '', godfather_gender: '', godfather_email: '', godfather_mobile: '', godfather_address: '', godfather_zip: '', godfather_city: '', godfather_state: '', godfather_country: '', godfather_rgpd: '', godfather_communications: '', godfather_cesion: '', godfather_cesion_foundation: '', godchild_firstname: 'Soldevilla', godchild_lastname: 'Pardo', godchild_fullname: 'Soldevilla Pardo', godchild_birthdate: '', godchild_aprox_age: '', godchild_nif: '', godchild_gender: '', godchild_email: '', godchild_mobile: '', godchild_address: '', godchild_zip: '', godchild_city: '', godchild_state: '', godchild_country: '', godchild_rgpd: '', godchild_communications: '', godchild_cesion: '', godchild_cesion_foundation: '' }, 
                { id: '333333333333333333333333', name: 'Producto nñopqrst', campaign: 'Navidad 2019', status: 'Caducado', store_code: '2MON', consumed_date: '15/12/2019', created_date: '15/12/2019', validation_date: '', sale_amount: '', godfather_firstname: 'Martínez', godfather_lastname: 'Rincón', godfather_fullname: 'Martínez Rincón', godfather_birthdate: '', godfather_aprox_age: '', godfather_nif: '', godfather_gender: '', godfather_email: '', godfather_mobile: '', godfather_address: '', godfather_zip: '', godfather_city: '', godfather_state: '', godfather_country: '', godfather_rgpd: '', godfather_communications: '', godfather_cesion: '', godfather_cesion_foundation: '', godchild_firstname: 'Puente', godchild_lastname: 'Jiménez', godchild_fullname: 'Puente Jiménez', godchild_birthdate: '', godchild_aprox_age: '', godchild_nif: '', godchild_gender: '', godchild_email: '', godchild_mobile: '', godchild_address: '', godchild_zip: '', godchild_city: '', godchild_state: '', godchild_country: '', godchild_rgpd: '', godchild_communications: '', godchild_cesion: '', godchild_cesion_foundation: '' }, 
                { id: '444444444444444444444444', name: 'Producto uvwxyz', campaign: 'Otoño Tchin Tchin', status: 'Caducado', store_code: 'FAHH', consumed_date: '02/10/2019', created_date: '02/10/2019', validation_date: '', sale_amount: '', godfather_firstname: 'Rincón', godfather_lastname: 'Martínez', godfather_fullname: 'Rincón Martínez', godfather_birthdate: '', godfather_aprox_age: '', godfather_nif: '', godfather_gender: '', godfather_email: '', godfather_mobile: '', godfather_address: '', godfather_zip: '', godfather_city: '', godfather_state: '', godfather_country: '', godfather_rgpd: '', godfather_communications: '', godfather_cesion: '', godfather_cesion_foundation: '', godchild_firstname: 'Jiménez', godchild_lastname: 'Puente', godchild_fullname: 'Jiménez Puente', godchild_birthdate: '', godchild_aprox_age: '', godchild_nif: '', godchild_gender: '', godchild_email: '', godchild_mobile: '', godchild_address: '', godchild_zip: '', godchild_city: '', godchild_state: '', godchild_country: '', godchild_rgpd: '', godchild_communications: '', godchild_cesion: '', godchild_cesion_foundation: '' }
            ], 
            headers: [
                { text: 'CUPÓN', value: 'name', sortable: false },
                { text: 'ESTADO', value: 'status', sortable: false },
                { text: 'PADRINO', value: 'godfather_fullname', sortable: false },
                { text: 'AHIJADO', value: 'godchild_fullname', sortable: false },
                { text: 'CREADO', value: 'created_date', sortable: false },
                { text: 'CONSUMIDO', value: 'consumed_date', sortable: false },
                { text: 'TIPO PROMOCIÓN', value: 'campaign', sortable: false },
                { text: 'TIENDA PADRINO', value: 'store_code', sortable: false },
                { text: 'TIENDA AHIJADO', value: 'store_code', sortable: false }
            ], 
            states: [
                { text: 'A Coruña', value: 'A Coruña' }, 
                { text: 'Álava', value: 'Álava' }, 
                { text: 'Albacete', value: 'Albacete' }, 
                { text: 'Alicante', value: 'Alicante' }, 
                { text: 'Almería', value: 'Almería' }, 
                { text: 'Asturias', value: 'Asturias' }, 
                { text: 'Ávila', value: 'Ávila' }, 
                { text: 'Badajoz', value: 'Badajoz' }, 
                { text: 'Barcelona', value: 'Barcelona' }, 
                { text: 'Burgos', value: 'Burgos' }, 
                { text: 'Cáceres', value: 'Cáceres' }, 
                { text: 'Cádiz', value: 'Cádiz' }, 
                { text: 'Cantabria', value: 'Cantabria' }, 
                { text: 'Castellón', value: 'Castellón' }, 
                { text: 'Ciudad Real', value: 'Ciudad Real' }, 
                { text: 'Córdoba', value: 'Córdoba' }, 
                { text: 'Cuenca', value: 'Cuenca' }, 
                { text: 'Gipuzkoa', value: 'Gipuzkoa' }, 
                { text: 'Girona', value: 'Girona' }, 
                { text: 'Granada', value: 'Granada' }, 
                { text: 'Guadalajara', value: 'Guadalajara' }, 
                { text: 'Huelva', value: 'Huelva' }, 
                { text: 'Huesca', value: 'Huesca' }, 
                { text: 'Islas Baleares', value: 'Islas Baleares' }, 
                { text: 'Jaén', value: 'Jaén' }, 
                { text: 'León', value: 'León' }, 
                { text: 'Lleida', value: 'Lleida' }, 
                { text: 'Lugo', value: 'Lugo' }, 
                { text: 'Madrid', value: 'Madrid' }, 
                { text: 'Málaga', value: 'Málaga' }, 
                { text: 'Murcia', value: 'Murcia' }, 
                { text: 'Navarra', value: 'Navarra' }, 
                { text: 'Ourense', value: 'Ourense' }, 
                { text: 'Palencia', value: 'Palencia' }, 
                { text: 'Las Palmas', value: 'Las Palmas' }, 
                { text: 'Pontevedra', value: 'Pontevedra' }, 
                { text: 'La Rioja', value: 'La Rioja' }, 
                { text: 'Salamanca', value: 'Salamanca' }, 
                { text: 'Segovia', value: 'Segovia' }, 
                { text: 'Sevilla', value: 'Sevilla' }, 
                { text: 'Soria', value: 'Soria' }, 
                { text: 'Tarragona', value: 'Tarragona' }, 
                { text: 'Santa Cruz de Tenerife', value: 'Santa Cruz de Tenerife' }, 
                { text: 'Teruel', value: 'Teruel' }, 
                { text: 'Toledo', value: 'Toledo' }, 
                { text: 'Valencia', value: 'Valencia' }, 
                { text: 'Valladolid', value: 'Valladolid' }, 
                { text: 'Vizcaya', value: 'Vizcaya' }, 
                { text: 'Zamora', value: 'Zamora' }, 
                { text: 'Zaragoza', value: 'Zaragoza' }
            ], 
            countries: [
                { text: 'España', value: 'España' }
            ], 
            rules: {
                normalRequired: value => !!value || 'Este campo es obligatorio.', 
                disabledRequired: value => (this.avoidStep2 || this.sameStep2 || !!value) || 'Este campo es obligatorio.', 
                validateDNI: value => this.validateDNI(value) || 'DNI/NIE no válido'
            }, 
            filters: {
                created_date: {
                    since: '', 
                    until: ''
                }, 
                consumed_date: {
                    since: '', 
                    until: ''
                }, 
                sales_type: [], 
                new_client: '', 
                sale_return: ''
            }
        }
    }, 
    methods: {
        openNewPromotion() {
            if (this.$refs.formCampaign.validate()) {
                this.newPromotionDialog = true;
                var currentCampaign = this.newPromotion.campaign;
                //this.newPromotion = { name: '', campaign: currentCampaign, status: '', store_code: '', consumed_date: '', created_date: '', validation_date: '', sale_amount: '', godfather_fullname: '', godfather_birthdate: '', godfather_nif: '', godfather_gender: '', godfather_email: '', godfather_mobile: '', godfather_address: '', godfather_zip: '', godfather_city: '', godfather_state: '', godfather_country: '', godfather_rgpd: '', godfather_communications: '', godfather_cesion: '', godfather_cesion_foundation: '', godchild_fullname: '', godchild_birthdate: '', godchild_nif: '', godchild_gender: '', godchild_email: '', godchild_mobile: '', godchild_address: '', godchild_zip: '', godchild_city: '', godchild_state: '', godchild_country: '', godchild_rgpd: '', godchild_communications: '', godchild_cesion: '', godchild_cesion_foundation: '' };
            }
        }, 
        searchByFilters() {
            this.menuFilters = false;
            this.loadingCoupons = true;
            axios
            .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/searchcoupon_portaltt', {
                simpleFilter: this.simpleFilter, 
                filters: this.filters, 
                storeCode: store.getters.getUser.store_code
            })
            .then(response => {
                this.coupons = response.data.data;
                this.loadingCoupons = false;
            })
            .catch(error => {
                console.log('Login query execution error ' + error);
                this.snackbar = true;
                this.errorMessage = 'Ha ocurrido un error buscando las ventas, por favor inténtelo más tarde.';
                this.loadingCoupons = false;
            });
        }, 
        clearFilters() {
            this.menuFilters = false;
            this.simpleFilter = '';
            this.filters = {
                created_date: {
                    since: '', 
                    until: ''
                }, 
                consumed_date: {
                    since: '', 
                    until: ''
                }, 
                sales_type: [], 
                new_client: '', 
                sale_return: ''
            };
            this.searchByFilters();
        }, 
        saveNewPromotion() {

            this.newPromotion.store_code = store.getters.getUser.store_code;
            this.newPromotion.created_date = new Date().toISOString().substr(0, 10);

            if (this.activeStep3) {
                this.newPromotion.status = 'Consumido';
                this.newPromotion.consumed_date = new Date().toISOString().substr(0, 10);
            } else {
                this.newPromotion.status = 'Vigente';
            }

            if (this.sameStep2) {
                this.newPromotion.godchild_firstname = this.newPromotion.godfather_firstname;
                this.newPromotion.godchild_lastname1 = this.newPromotion.godfather_lastname1;
                this.newPromotion.godchild_lastname2 = this.newPromotion.godfather_lastname2;
                this.newPromotion.godchild_birthdate = this.newPromotion.godfather_birthdate;
                this.newPromotion.godchild_nif = this.newPromotion.godfather_nif;
                this.newPromotion.godchild_aprox_age = this.newPromotion.godfather_aprox_age;
                this.newPromotion.godchild_rgpd = this.newPromotion.godfather_rgpd;
                this.newPromotion.godchild_gender = this.newPromotion.godfather_gender;
                this.newPromotion.godchild_email = this.newPromotion.godfather_email;
                this.newPromotion.godchild_mobile = this.newPromotion.godfather_mobile;
                this.newPromotion.godchild_address = this.newPromotion.godfather_address;
                this.newPromotion.godchild_zip = this.newPromotion.godfather_zip;
                this.newPromotion.godchild_city = this.newPromotion.godfather_city;
                this.newPromotion.godchild_state = this.newPromotion.godfather_state;
                this.newPromotion.godchild_country = this.newPromotion.godfather_country;
                this.newPromotion.godchild_communications = this.newPromotion.godfather_communications;
                this.newPromotion.godchild_cesion = this.newPromotion.godfather_cesion;
                this.newPromotion.godchild_cesion_foundation = this.newPromotion.godfather_cesion_foundation;
            } else if (this.avoidStep2) {
                this.newPromotion.godchild_firstname = '';
                this.newPromotion.godchild_lastname1 = '';
                this.newPromotion.godchild_lastname2 = '';
                this.newPromotion.godchild_birthdate = null;
                this.newPromotion.godchild_nif = '';
                this.newPromotion.godchild_aprox_age = false;
                this.newPromotion.godchild_rgpd = false;
                this.newPromotion.godchild_gender = '';
                this.newPromotion.godchild_email = '';
                this.newPromotion.godchild_mobile = '';
                this.newPromotion.godchild_address = '';
                this.newPromotion.godchild_zip = '';
                this.newPromotion.godchild_city = '';
                this.newPromotion.godchild_state = '';
                this.newPromotion.godchild_country = '';
                this.newPromotion.godchild_communications = false;
                this.newPromotion.godchild_cesion = false;
                this.newPromotion.godchild_cesion_foundation = false;

            }

            console.log(this.newPromotion);
            if (this.currentStep == '1') {
                if (!this.$refs.formStep1.validate()) {
                    return;
                }
            } else if (this.currentStep == '2') {
                if (!this.$refs.formStep2.validate()) {
                    return;
                }
            }

            axios
            .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/newcoupon_portaltt', {
                newPromotion: this.newPromotion
            })
            .then(response => {
                // TODO  - this.searchByFilters();
                //this.newPromotion = { id: '', name: '', campaign: '', status: '', store_code: '', consumed_date: '', created_date: '', validation_date: '', sale_amount: '', godfather_fullname: '', godfather_birthdate: '', godfather_nif: '', godfather_gender: '', godfather_email: '', godfather_mobile: '', godfather_address: '', godfather_zip: '', godfather_city: '', godfather_state: '', godfather_country: '', godfather_rgpd: '', godfather_communications: '', godfather_cesion: '', godfather_cesion_foundation: '', godchild_fullname: '', godchild_birthdate: '', godchild_nif: '', godchild_gender: '', godchild_email: '', godchild_mobile: '', godchild_address: '', godchild_zip: '', godchild_city: '', godchild_state: '', godchild_country: '', godchild_rgpd: '', godchild_communications: '', godchild_cesion: '', godchild_cesion_foundation: '' };
                this.newPromotionDialog = false;
                
                this.currentStep = '1';
                this.instantConsume = false;
                this.avoidStep2 = false;
                this.sameStep2 = false;
                this.showStep2 = true;
                this.activeStep2 = false;
                this.showStep3 = false;
                this.activeStep3 = false;
                this.validFormStep1 = false;
                this.validFormStep2 = false;
                this.validFormCampaign = false, false;

                if (this.$refs.formCampaign) {
                    this.$refs.formCampaign.reset();
                    this.$refs.formCampaign.resetValidation();
                }
                if (this.$refs.formStep1) {
                    this.$refs.formStep1.reset();
                    this.$refs.formStep1.resetValidation();
                }
                if (this.$refs.formStep2) {
                    this.$refs.formStep2.reset();
                    this.$refs.formStep2.resetValidation();
                }
                
            })
            .catch(error => {
                console.log('NewPromotion query execution error ' + error);
                this.snackbar = true;
                this.errorMessage = 'Ha ocurrido un error creando la promoción, por favor inténtelo más tarde.';
            });
        }, 
        getCoupon() {
            if (this.$refs.formCoupon.validate()) {
                axios
                .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/getCoupon_portaltt', {
                    couponCode: this.couponCode
                })
                .then(response => {
                    const currentCoupon = '' + this.couponCode;
                    this.$router.push({ path: '/coupon-detail', query: { coupon: currentCoupon } });
                    this.couponCode = '';
                })
                .catch(error => {
                    console.log('GetCoupon query execution error ' + error);
                    this.snackbar = true;
                    this.errorMessage = 'Ha ocurrido un error obteniendo el cupón, por favor inténtelo más tarde.';
                });
            }
        }, 
        csvExport(fileName, items) {
            
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(_.omit(this.coupons[0], 'id')).join(";")
                .replace('sale_amount', 'Importe de la venta padrino')
                .replace('godfather_firstname', 'Nombre (Padrino)')
                .replace('godfather_lastname', 'Apellidos (Padrino)')
                .replace('godfather_fullname', 'Nombre completo (Padrino)')
                .replace('godfather_birthdate', 'Fecha de nacimiento (Padrino)')
                //.replace('godfather_aprox_age', 'Edad Aproximada (Padrino)')
                .replace('godfather_nif', 'DNI/NIE (Padrino)')
                .replace('godfather_gender', 'Género (Padrino)')
                .replace('godfather_email', 'Email (Padrino)')
                .replace('godfather_mobile', 'Móvil (Padrino)')
                .replace('godfather_address', 'Dirección (Padrino)')
                .replace('godfather_zip', 'CP (Padrino)')
                .replace('godfather_city', 'Población (Padrino)')
                .replace('godfather_state', 'Provincia (Padrino)')
                .replace('godfather_country', 'País (Padrino)')
                .replace('godfather_rgpd', 'Firmado RGPD (Padrino)')
                .replace('godfather_communications', 'Comunicaciones comerciales Alain Afflelou (Padrino)')
                .replace('godfather_cesion', 'Cesión otras ópticas (Padrino)')
                .replace('godfather_cesion_foundation', 'Cesión Fundación Alain Afflelou (Padrino)')
                .replace('godchild_firstname', 'Nombre (Ahijado)')
                .replace('godchild_lastname', 'Apellidos (Ahijado)')
                .replace('godchild_fullname', 'Nombre completo (Ahijado)')
                .replace('godchild_birthdate', 'Fecha de nacimiento (Ahijado)')
                //.replace('godchild_aprox_age', 'Edad Aproximada (Ahijado)')
                .replace('godchild_nif', 'DNI/NIE (Ahijado)')
                .replace('godchild_gender', 'Género (Ahijado)')
                .replace('godchild_email', 'Email (Ahijado)')
                .replace('godchild_mobile', 'Móvil (Ahijado)')
                .replace('godchild_address', 'Dirección (Ahijado)')
                .replace('godchild_zip', 'CP (Ahijado)')
                .replace('godchild_city', 'Población (Ahijado)')
                .replace('godchild_state', 'Provincia (Ahijado)')
                .replace('godchild_country', 'País (Ahijado)')
                .replace('godchild_rgpd', 'Firmado RGPD (Ahijado)')
                .replace('godchild_communications', 'Comunicaciones comerciales Alain Afflelou (Ahijado)')
                .replace('godchild_cesion', 'Cesión otras ópticas (Ahijado)')
                .replace('godchild_cesion_foundation', 'Cesión Fundación Alain Afflelou (Ahijado)')
                .replace('name', 'Cupón')
                .replace('campaign', 'Promoción')
                .replace('status', 'Estado')
                .replace('store_code', 'Tienda')
                .replace('consumed_date', 'Fecha Consumo')
                .replace('created_date', 'Fecha Creación')
                .replace('validation_date', 'Fecha Validez'), 
                ...this.coupons.map(item => Object.values(_.omit(item, 'id')).join(";").replace('true', 'Si').replace('false', 'No'))
            ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", `Cupones-${store.getters.getUser.store_code}-` + new Date().toLocaleDateString() + ".csv");
            link.click();
        }, 
        toStep1() {
            this.currentStep = '1';
            this.activeStep2 = this.avoidStep2 || this.sameStep2;
            this.activeStep3 = false;
        }, 
        toStep2() {
            if (this.currentStep == '1') {
                if (this.$refs.formStep1.validate()) {
                    this.currentStep = '2';
                    this.activeStep2 = true;
                    this.activeStep3 = false;
                }
            } else {
                this.currentStep = '2';
                this.activeStep2 = true;
                this.activeStep3 = false;

                if (this.sameStep2) {
                    this.newPromotion.godchild_firstname = this.newPromotion.godfather_firstname;
                    this.newPromotion.godchild_lastname1 = this.newPromotion.godfather_lastname1;
                    this.newPromotion.godchild_lastname2 = this.newPromotion.godfather_lastname2;
                    this.newPromotion.godchild_birthdate = this.newPromotion.godfather_birthdate;
                    this.newPromotion.godchild_nif = this.newPromotion.godfather_nif;
                    this.newPromotion.godchild_aprox_age = this.newPromotion.godfather_aprox_age;
                    this.newPromotion.godchild_rgpd = this.newPromotion.godfather_rgpd;
                    this.newPromotion.godchild_gender = this.newPromotion.godfather_gender;
                    this.newPromotion.godchild_email = this.newPromotion.godfather_email;
                    this.newPromotion.godchild_mobile = this.newPromotion.godfather_mobile;
                    this.newPromotion.godchild_address = this.newPromotion.godfather_address;
                    this.newPromotion.godchild_zip = this.newPromotion.godfather_zip;
                    this.newPromotion.godchild_city = this.newPromotion.godfather_city;
                    this.newPromotion.godchild_state = this.newPromotion.godfather_state;
                    this.newPromotion.godchild_country = this.newPromotion.godfather_country;
                    this.newPromotion.godchild_communications = this.newPromotion.godfather_communications;
                    this.newPromotion.godchild_cesion = this.newPromotion.godfather_cesion;
                    this.newPromotion.godchild_cesion_foundation = this.newPromotion.godfather_cesion_foundation;
                }
            }
        }, 
        toStep3() {
            if (this.currentStep == '1' && (this.avoidStep2 || this.sameStep2)) {
                if (this.$refs.formStep1.validate()) {
                    this.currentStep = '3';
                    this.activeStep2 = true;
                    this.activeStep3 = true;

                    this.newPromotion.store_code = store.getters.getUser.store_code;
                    this.newPromotion.consumed_date = new Date().toISOString().substr(0, 10);
                    this.newPromotion.created_date = new Date().toISOString().substr(0, 10);

                    if (this.sameStep2) {
                        this.newPromotion.godchild_firstname = this.newPromotion.godfather_firstname;
                        this.newPromotion.godchild_lastname1 = this.newPromotion.godfather_lastname1;
                        this.newPromotion.godchild_lastname2 = this.newPromotion.godfather_lastname2;
                        this.newPromotion.godchild_birthdate = this.newPromotion.godfather_birthdate;
                        this.newPromotion.godchild_nif = this.newPromotion.godfather_nif;
                        this.newPromotion.godchild_aprox_age = this.newPromotion.godfather_aprox_age;
                        this.newPromotion.godchild_rgpd = this.newPromotion.godfather_rgpd;
                        this.newPromotion.godchild_gender = this.newPromotion.godfather_gender;
                        this.newPromotion.godchild_email = this.newPromotion.godfather_email;
                        this.newPromotion.godchild_mobile = this.newPromotion.godfather_mobile;
                        this.newPromotion.godchild_address = this.newPromotion.godfather_address;
                        this.newPromotion.godchild_zip = this.newPromotion.godfather_zip;
                        this.newPromotion.godchild_city = this.newPromotion.godfather_city;
                        this.newPromotion.godchild_state = this.newPromotion.godfather_state;
                        this.newPromotion.godchild_country = this.newPromotion.godfather_country;
                        this.newPromotion.godchild_communications = this.newPromotion.godfather_communications;
                        this.newPromotion.godchild_cesion = this.newPromotion.godfather_cesion;
                        this.newPromotion.godchild_cesion_foundation = this.newPromotion.godfather_cesion_foundation;
                    }
                }
            } else if (this.currentStep == '2') {
                if (this.$refs.formStep2.validate()) {
                    this.currentStep = '3';
                    this.activeStep2 = true;
                    this.activeStep3 = true;

                    this.newPromotion.store_code = store.getters.getUser.store_code;
                    this.newPromotion.consumed_date = new Date().toISOString().substr(0, 10);
                    this.newPromotion.created_date = new Date().toISOString().substr(0, 10);

                    if (this.sameStep2) {
                        this.newPromotion.godchild_firstname = this.newPromotion.godfather_firstname;
                        this.newPromotion.godchild_lastname1 = this.newPromotion.godfather_lastname1;
                        this.newPromotion.godchild_lastname2 = this.newPromotion.godfather_lastname2;
                        this.newPromotion.godchild_birthdate = this.newPromotion.godfather_birthdate;
                        this.newPromotion.godchild_nif = this.newPromotion.godfather_nif;
                        this.newPromotion.godchild_aprox_age = this.newPromotion.godfather_aprox_age;
                        this.newPromotion.godchild_rgpd = this.newPromotion.godfather_rgpd;
                        this.newPromotion.godchild_gender = this.newPromotion.godfather_gender;
                        this.newPromotion.godchild_email = this.newPromotion.godfather_email;
                        this.newPromotion.godchild_mobile = this.newPromotion.godfather_mobile;
                        this.newPromotion.godchild_address = this.newPromotion.godfather_address;
                        this.newPromotion.godchild_zip = this.newPromotion.godfather_zip;
                        this.newPromotion.godchild_city = this.newPromotion.godfather_city;
                        this.newPromotion.godchild_state = this.newPromotion.godfather_state;
                        this.newPromotion.godchild_country = this.newPromotion.godfather_country;
                        this.newPromotion.godchild_communications = this.newPromotion.godfather_communications;
                        this.newPromotion.godchild_cesion = this.newPromotion.godfather_cesion;
                        this.newPromotion.godchild_cesion_foundation = this.newPromotion.godfather_cesion_foundation;
                    }
                }
            }
        }, 
        changeStep3() {
            if (this.instantConsume) {
                this.showStep3 = true;
                this.activeStep3 = false;
            } else {
                this.showStep3 = false;
                this.activeStep3 = false;
                if (this.currentStep == '3' && this.showStep2) {
                    this.currentStep = '2';
                } else {
                    this.currentStep = '1';
                }
            }
        }, 
        changeStep2() {
            if (this.avoidStep2 || this.sameStep2) {
                this.showStep2 = false;
                this.activeStep2 = false;
                if (this.currentStep == '2') {
                    if (this.instantConsume) {
                        this.currentStep = '3';
                    } else {
                        this.currentStep = '1';
                    }
                }
            } else {
                this.showStep2 = true;
                this.activeStep2 = false;
            }
        }, 
        cancelDialog() {
            this.newPromotionDialog = false;
            if (this.$refs.formCampaign) {
                this.$refs.formCampaign.reset();
                this.$refs.formCampaign.resetValidation();
            }
            if (this.$refs.formStep1) {
                this.$refs.formStep1.reset();
                this.$refs.formStep1.resetValidation();
            }
            if (this.$refs.formStep2) {
                this.$refs.formStep2.reset();
                this.$refs.formStep2.resetValidation();
            }
        }, 
        validateDNI(value) {

            if (!value)
                return true;

            var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
            var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
            var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
            var str = value.toString().toUpperCase();

            var isNIF = nifRexp.test(str);
            var isNIE = nieRexp.test(str);
            if (!isNIF && !isNIE) 
                return false;

            var nie = str
                .replace(/^[X]/, '0')
                .replace(/^[Y]/, '1')
                .replace(/^[Z]/, '2');

            var letter = str.substr(-1);
            var charIndex = parseInt(nie.substr(0, 8)) % 23;

            if (validChars.charAt(charIndex) === letter)
                return true;

            return false;
        }
    }, 
    mounted() {
        this.searchByFilters();
    }, 
    computed: {
        godchild_fullname() {
            if (this.newPromotion.godchild_firstname) {
                return this.newPromotion.godchild_firstname + ' ' + this.newPromotion.godchild_lastname1 + ' ' + this.newPromotion.godchild_lastname2;
            } else {
                return '';
            }
        }
    }, 
    watch: {
        'newPromotion.sale_amount': function(nv) {
            if (nv) {
                this.newPromotion.sale_amount = nv.replace('.', '');
            }
        }
    }
}
</script>
<style>
.coupons {
    padding: 50px 100px;
}
.new-promotions, .consume-coupons, .search-coupons {
    margin-bottom: 50px;
}
.consume-coupons p {
    margin-top: 25px;
}
.action-buttons {
    padding: 20px 35px !important;
}
.filter-button {
    padding: 20px 35px !important;
}
.section-title {
    border-bottom: 2px solid #C3A269;
    display: inline-block;
}
.coupons-table th, td {
    border-bottom: none !important;
}
.coupons-table tbody tr:hover {
    background-color: #F9FAFD !important;
    color: #C3A269;
}
.v-input--reverse .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.v-input--reverse {
    display: inline-block !important;
}
</style>