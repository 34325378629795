<template>
    <div class="popupUser">
        <v-card>
            <v-card-title>
                <span class="headline" v-if="this.mode == 'create'">CREAR NUEVO USUARIO</span>
                <span class="headline" v-if="this.mode == 'edit'">EDITAR USUARIO</span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="validForm"
                    lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                label="Nombre"
                                :rules="[rules.required]"
                                v-model="newUser.nombre"
                                autocomplete="new-password">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                label="Apellidos"
                                :rules="[rules.required]"
                                v-model="newUser.apellidos"
                                autocomplete="new-password">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                label="Username"
                                :rules="[rules.required]"
                                v-model="newUser.user_name"
                                autocomplete="new-password">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass ? 'text' : 'password'"
                                label="Contraseña"
                                :rules="[rules.required]"
                                required
                                v-model="newUser.password"
                                @click:append="showPass = !showPass"
                                autocomplete="new-password"
                                v-if="mode == 'create'">
                            </v-text-field>
                            <v-text-field
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass ? 'text' : 'password'"
                                label="Crear nueva contraseña"
                                required
                                v-model="newUser.password"
                                @click:append="showPass = !showPass"
                                autocomplete="new-password"
                                v-if="mode == 'edit'">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="superAdmin">
                        <v-col cols="12" sm="6" md="6">
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-switch v-model="newUser.store_admin" label="Administrador de tienda"></v-switch>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="close">CANCELAR</v-btn>
                <v-btn tile color="primary" @click="save">GUARDAR</v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-model="snackbar" color="error">
            {{ errorMessage }}
            <v-btn dark text
                @click="snackbar = false">
                Cerrar
            </v-btn>
        </v-snackbar>

    </div>
</template>
<script>
import axios from 'axios'
import crypto from 'crypto'
import store from '@/store/index.js'
export default {
    name: 'PopUpNewUser',
    props: {
        mode: { type: String, default: 'create' }, 
        user: { type: Object }
    },
    data() {
        return {
            newUser: {
                nombre: '', 
                apellidos: '', 
                user_name: '', 
                password: '', 
                store_admin: false
            }, 
            validForm: true, 
            showPass: false, 
            snackbar: false, 
            errorMessage: '', 
            rules: {
                required: value => !!value || 'Este campo es obligatorio.'
            },
        }
    }, 
    computed: {
        superAdmin() {
            return store.getters.getUser.super_admin;
        }
    }, 
    methods: {
        save() {
            if(this.$refs.form.validate()) {
                let userpass = crypto.createHash('sha256').update(this.newUser.password).digest('hex');
                if (this.mode == 'edit') {
                    userpass = null;
                }
                axios
                .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/newuser_portaltt', {
                    storeCode: store.getters.getUser.store_code, 
                    society: store.getters.getUser.society, 
                    newUser: this.newUser, 
                    userPass: userpass, 
                    mode: this.mode
                })
                .then(response => {
                    if(response.data.data) {
                        this.snackbar = true;
                        if (this.mode == 'create') {
                            this.errorMessage = 'Usuario insertado.'
                        } else {
                            this.errorMessage = 'Usuario actualizado.'
                        }
                        this.$refs.form.reset();
                        this.$emit('close');
                    }
                    this.$emit('close');
                })
                .catch(error => {
                    console.log('Login query execution error ' + error);
                    this.snackbar = true;
                    if (this.mode == 'create') {
                        this.errorMessage = 'Ha habido un error insertando el usuario, por favor inténtelo más tarde.'
                    } else {
                        this.errorMessage = 'Ha habido un error actualizando el usuario, por favor inténtelo más tarde.'
                    }
                    this.$emit('close');
                });
            }
        }, 
        close() {
            this.$emit('close');
        }
    }, 
    watch: {
        //Copy prop to allow inner modifications
        user: {
            immediate: true,
            handler(nv, oldv) {
                if(nv !== undefined && nv !== null) {
                    this.newUser = Object.assign({}, nv)
                    this.newUser.password = '';
                    if (this.$refs.form) {
                        this.$refs.form.resetValidation();
                    }
                } else {
                    this.newUser = {
                        nombre: '', 
                        apellidos: '', 
                        user_name: '', 
                        password: '', 
                        store_admin: false
                    }
                    if (this.$refs.form) {
                        this.$refs.form.reset();
                        this.$refs.form.resetValidation();
                    }
                }
            }
        }
    }
}
</script>