<template>
  <div class="loyalty-card">
    
    <!-- Crear promociones -->
    <div class="new-sale-loyalty-card">
        <h2 class="section-title font-weight-black">Tarjetas Fidelidad Carrefour</h2>
        <p>Registra aquí las ventas realizadas con la Tarjeta Fidelidad de Carrefour.</p>
        <v-btn class="action-buttons" tile color="primary" dark @click="openNewSale">NUEVA VENTA</v-btn>
    </div>

    <!-- Buscador ventas -->
    <div class="search-sales-loyalty-card">
        <h2 class="section-title font-weight-black">Buscador de ventas</h2>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    single-line
                    outlined
                    flat
                    label="Buscar ventas por nº de venta, nº de tarjeta o sucursal"
                    append-icon="mdi-magnify"
                    v-model="simpleFilter"
                    @keypress.native.enter="searchByFilters">
                </v-text-field>
                <p class="font-weight-black">{{ salesLoyaltyCard.length }} resultados encontrados</p>
            </v-col>
            <v-col cols="12" md="4">
                <v-menu
                    left tile 
                    offset-y 
                    v-model="menuFilters"
                    :close-on-content-click=false>
                    <template v-slot:activator="{ on }">
                        <v-btn class="filter-button" tile color="#E7E8EB" v-on="on">
                            <v-icon left>mdi-filter-variant</v-icon>
                            <span>FILTROS</span>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-row class="filters-popup">
                            <v-col cols="12" md="3">
                                <p>Fecha venta</p>
                                <v-menu
                                    ref="menuSaleDateSince"
                                    v-model="menuSaleDateSince"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterSaleDateSince"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.sales_date.since"
                                            label="Desde"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.sales_date.since" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuSaleDateSince = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuSaleDateSince.save(filterSaleDateSince)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    ref="menuSaleDateUntil"
                                    v-model="menuSaleDateUntil"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterSaleDateUntil"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.sales_date.until"
                                            label="Hasta"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.sales_date.until" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuSaleDateUntil = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuSaleDateUntil.save(filterSaleDateUntil)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <p>Fecha registro</p>
                                <v-menu
                                    ref="menuRegisterDateSince"
                                    v-model="menuRegisterDateSince"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterRegisterDateSince"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.register_date.since"
                                            label="Desde"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.register_date.since" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuRegisterDateSince = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuRegisterDateSince.save(filterRegisterDateSince)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    ref="menuRegisterDateUntil"
                                    v-model="menuRegisterDateUntil"
                                    :close-on-content-click="false"
                                    :return-value.sync="filterRegisterDateUntil"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filters.register_date.until"
                                            label="Hasta"
                                            append-icon="mdi-calendar-text"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="date = null"
                                            clearable>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.register_date.until" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuRegisterDateUntil = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.menuRegisterDateUntil.save(filterRegisterDateUntil)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <p>Tipo de venta</p>
                                <v-checkbox v-model="filters.sales_type" label="Graduado" value="Graduado"></v-checkbox>
                                <v-checkbox v-model="filters.sales_type" label="Sol" value="Sol"></v-checkbox>
                                <v-checkbox v-model="filters.sales_type" label="Lentes de contacto" value="Lentes de contacto"></v-checkbox>
                                <v-checkbox v-model="filters.sales_type" label="Varios" value="Varios"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="3">
                                <p>¿Nuevo cliente?</p>
                                <v-checkbox v-model="filters.new_client" label="Sí" value="true"></v-checkbox>
                                <v-checkbox v-model="filters.new_client" label="No" value="false"></v-checkbox>
                                <p>¿Venta o devolución?</p>
                                <v-checkbox v-model="filters.sale_return" label="Venta" value="Venta"></v-checkbox>
                                <v-checkbox v-model="filters.sale_return" label="Devolución" value="Devolución"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="clearFilters">LIMPIAR FILTROS</v-btn>
                            <v-btn color="primary" text @click="searchByFilters">APLICAR FILTROS</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn rounded outlined color="primary" dense class="ma-2" @click="csvExport()">
                    <v-icon>mdi-download</v-icon> DESCARGAR EXCEL
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="salesLoyaltyCard"
            :items-per-page="5"
            :loading="loadingSales"
            class="sales-loyalty-card-table">
        </v-data-table>
    </div>

    <v-dialog v-model="newSaleDialog" max-width="1200px">
        <v-card>
            <v-card-title class="headline lighten-2">
            Nueva venta con Tarjeta Fidelidad Carrefour
            </v-card-title>

            <v-card-text>
                <v-form
                ref="form"
                v-model="validForm"
                lazy-validation>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Número de venta"
                                v-model="newSale.num_sale"
                                :rules="[rules.required]"
                                :errors="numSalesErrors">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Importe de venta (MONTURA + GAFAS)"
                                v-model="newSale.sales_amount"
                                :rules="[rules.oneRequired]">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Importe de venta (RESTO DE PRODUCTOS)"
                                v-model="newSale.sales_rest"
                                :rules="[rules.oneRequired]">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="newSaleDate"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">

                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="newSale.sale_date"
                                        label="Fecha de la venta"
                                        append-icon="mdi-calendar-text"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required, rules.minDate]">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="newSale.sale_date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(newSaleDate)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <p>¿Venta o devolución?</p>
                            <v-radio-group row 
                                v-model="newSale.sale_return" 
                                :rules="[rules.required]">
                                <v-radio label="Venta" value="Venta"></v-radio>
                                <v-radio label="Devolución" value="Devolución"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <p>Tipo de venta</p>
                            <v-radio-group row 
                            v-model="newSale.sales_type" 
                            :rules="[rules.required]">
                                <v-radio label="Graduado" value="Graduado"></v-radio>
                                <v-radio label="Sol" value="Sol"></v-radio>
                                <v-radio label="Lentes de contacto" value="Lentes de contacto"></v-radio>
                                <v-radio label="Varios" value="Varios"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-select 
                                :items="cardTypes" 
                                label="Tipo de tarjeta"
                                v-model="newSale.card_type"
                                :rules="[rules.required]">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                label="Número de tarjeta"
                                v-model="newSale.card_num"
                                :rules="[rules.required, rules.counter, rules.cardInit, rules.luhnAlgorithm, rules.ean13Algorithm, rules.originId, rules.passMastercard]"
                                counter="13"
                                maxlength="13"
                                minlength="13">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            ¿Nuevo cliente?
                            <v-radio-group row 
                                v-model="newSale.new_client" 
                                :rules="[rules.required]">
                                <v-radio label="Sí" value="true"></v-radio>
                                <v-radio label="No" value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="cancelNewSale()">CANCELAR</v-btn>
                <v-btn class="action-buttons" tile color="primary" dark :disabled="submitBtnBlocked" @click="createNewSale()">GUARDAR VENTA</v-btn>
            </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="millarWarning" max-width="290">
      <v-card>
        <v-card-title class="headline">
            Aviso
        </v-card-title>
        <v-card-text>
          El importe de la venta es de: {{parseFloat(this.newSale.sales_amount).toLocaleString('es-ES')}}€ este supera los 1.000€<br/><br/>
          <span class="text-xs-center"><b>¿Seguro que quiere continuar?</b></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="millarWarning = false">No</v-btn>
          <v-btn class="action-buttons" tile color="primary" dark @click="saveNewSale">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="error">
        {{ errorMessage }}
        <v-btn dark text
        @click="snackbar = false">
            Cerrar
        </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import store from '@/store/index.js'
export default {
    name: 'LoyaltyCard',
    data() {
        return {
            validForm: true, 
            submitBtnBlocked: false, 
            newSaleDialog: false, 
            millarWarning: false, 
            menuFilters: false, 
            menu: false,
            modal: false,
            snackbar: false, 
            errorMessage: '', 
            numSalesErrors: [], 
            newSaleDate: '', 
            filterSaleDateSince: '', 
            filterSaleDateUntil: '', 
            filterRegisterDateSince: '', 
            filterRegisterDateUntil: '', 
            menuSaleDateUntil: '', 
            menuSaleDateSince: '', 
            menuRegisterDateSince: '', 
            menuRegisterDateUntil: '', 
            loadingSales: false, 
            simpleFilter: '', 
            newSale: { 
                store_code: '', 
                num_sale: '', 
                sale_date: new Date().toISOString().substr(0, 10), 
                register_date: '', 
                sales_amount: '', 
                sales_rest: '', 
                card_type: 'Carrefour', 
                sales_type: '', 
                card_num: '', 
                new_client: '', 
                sale_return: '' 
            }, 
            salesLoyaltyCard: [
                /*{ num_sale: '5789456', sale_date: '29/01/2020', register_date: '29/01/2020', sales_amount: '200€', sales_rest: '50€', card_type: '29/01/2020', sales_type: 'Carrefour', card_num: 'Graduado', new_client: 'Sí', sale_return: 'Venta' }, 
                { num_sale: '3214569', sale_date: '22/01/2020', register_date: '22/01/2020', sales_amount: '100€', sales_rest: '10€', card_type: '22/01/2020', sales_type: 'Carrefour', card_num: 'Sol', new_client: 'No', sale_return: 'Venta' }, 
                { num_sale: '1235879', sale_date: '15/12/2019', register_date: '22/01/2020', sales_amount: '50€', sales_rest: '20€', card_type: '15/12/2019', sales_type: 'Carrefour', card_num: 'Sol', new_client: 'No', sale_return: 'Venta' }, 
                { num_sale: '789456123', sale_date: '02/10/2019', register_date: '20/01/2020', sales_amount: '250€', sales_rest: '150€', card_type: '02/10/2019', sales_type: 'Carrefour', card_num: 'Graduado', new_client: 'Sí', sale_return: 'Devolución' }*/
            ], 
            headers: [
                { text: 'Nº VENTA', value: 'num_sale', sortable: false, align: 'center' },
                { text: 'FECHA VENTA', value: 'sale_date', sortable: false, align: 'center' },
                { text: 'FECHA REGISTRO', value: 'register_date', sortable: false, align: 'center' },
                { text: 'IMPORTE VENTA', value: 'sales_amount', sortable: false, align: 'center' },
                { text: 'IMPORTE RESTO', value: 'sales_rest', sortable: false, align: 'center' },
                { text: 'TIPO TARJETA', value: 'card_type', sortable: false, align: 'center' },
                { text: 'TIPO VENTA', value: 'sales_type', sortable: false, align: 'center' },
                { text: 'Nº TARJETA', value: 'card_num', sortable: false, align: 'center' },
                { text: '¿CLIENTE NUEVO?', value: 'new_client', sortable: false, align: 'center' },
                { text: 'VENTA O DEV.', value: 'sale_return', sortable: false, align: 'center' }
            ], 
            cardTypes: [
                { text: 'Carrefour', value: 'Carrefour' }
            ], 
            rules: {
                required: value => !!value || 'Este campo es obligatorio.', 
                oneRequired: value => this.amountsFilled || 'Uno de los campos de importe debe estar relleno.', 
                counter: value => value.length >= 13 || 'Mínimo 13 caracteres', 
                cardInit: value => (value.substring(0,2) == '27' || value.substring(0,2) == '20') || 'Debe empezar por 20 o por 27', 
                minDate: value => new Date(value) >= new Date(new Date().getFullYear(), new Date().getMonth(), 1) || 'Debe ser superior al mes en curso', 
                luhnAlgorithm: value => this.luhnAlgorithm(value) || 'El primer dígito de control no es correcto', 
                ean13Algorithm: value => this.ean13Algorithm(value) || 'El segundo dígito de control no es correcto', 
                originId: value => this.originId(value) || 'El código de identificación del origen no es correcto (valores permitidos: 9, 8, 5)', 
                passMastercard: value => this.passMastercard(value) || 'El número de cuenta MasterCard debe empezar por 0'
            },
            filters: {
                sales_date: {
                    since: '', 
                    until: ''
                }, 
                register_date: {
                    since: '', 
                    until: ''
                }, 
                sales_type: [], 
                new_client: '', 
                sale_return: ''
            }
        }
    }, 
    computed: {
        amountsFilled() {
            //return this.newSale.sales_amount != '' || this.newSale.sales_rest != '';
            var controlFilled = '';
            if (this.newSale.sales_amount != '') {
                controlFilled = this.newSale.sales_amount;
            }
            if (this.newSale.sales_rest != '') {
                controlFilled = this.newSale.sales_rest;
            }
            return controlFilled != '';
        }
    }, 
    methods: {
        openNewSale() {
            this.newSaleDialog = true;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.newSale = { store_code: '', num_sale: '', sale_date: new Date().toISOString().substr(0, 10), register_date: '', sales_amount: '', sales_rest: '', card_type: 'Carrefour', sales_type: '', card_num: '', new_client: '', sale_return: '' };
        }, 
        createNewSale() {
            this.submitBtnBlocked = true;
            console.log(this.submitBtnBlocked);
            if(this.$refs.form.validate()) {
                this.newSale.store_code = store.getters.getUser.store_code;
                if (this.newSale.sales_amount > 1000) {
                    this.millarWarning = true;
                } else {
                    this.saveNewSale();
                    
                }
            } else {
                this.submitBtnBlocked = false;
                console.log(this.submitBtnBlocked);
            }
        }, 
        saveNewSale() {
            if (this.newSale.sale_return.indexOf('Venta') == 0) {
                axios
                .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/salesbystore_portaltt', {
                    newSale: this.newSale.num_sale, 
                    storeCode: store.getters.getUser.store_code
                })
                .then(response => {
                    if(response.data.data.length > 0) {
                        this.snackbar = true;
                        this.errorMessage = `El número de venta ${this.newSale.num_sale} debe ser único para la tienda ${this.newSale.store_code}.`;
                    } else {
                        axios
                        .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/newsale_portaltt', {
                            newSale: this.newSale
                        })
                        .then(response => {
                            this.newSale = { store_code: ' ', num_sale: ' ', sale_date: new Date().toISOString().substr(0, 10), register_date: ' ', sales_amount: ' ', sales_rest: ' ', card_type: 'Carrefour', sales_type: ' ', card_num: ' ', new_client: ' ', sale_return: ' ' };
                            this.newSaleDialog = false;
                            this.millarWarning = false;
                            this.searchByFilters();
                            this.submitBtnBlocked = false;
                        })
                        .catch(error => {
                            console.log('Login query execution error ' + error);
                            this.snackbar = true;
                            this.errorMessage = 'Ha ocurrido un error creando la nueva venta, por favor inténtelo más tarde.';
                        });
                    }
                })
                .catch(error => {
                    console.log('NewSale query execution error ' + error);
                    this.snackbar = true;
                    this.errorMessage = 'Ha ocurrido un error validando la venta, por favor inténtelo más tarde.';
                });
            } else {
                axios
                .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/newsale_portaltt', {
                    newSale: this.newSale
                })
                .then(response => {
                    this.newSale = { store_code: '', num_sale: '', sale_date: new Date().toISOString().substr(0, 10), register_date: '', sales_amount: '', sales_rest: '', card_type: 'Carrefour', sales_type: '', card_num: '', new_client: '', sale_return: '' };
                    this.newSaleDialog = false;
                    this.millarWarning = false;
                    this.searchByFilters();
                    this.submitBtnBlocked = false;
                })
                .catch(error => {
                    console.log('NewSale query execution error ' + error);
                    this.snackbar = true;
                    this.errorMessage = 'Ha ocurrido un error creando la nueva venta, por favor inténtelo más tarde.';
                });
            }
            
        }, 
        cancelNewSale() {
            this.newSaleDialog = false;
            this.$refs.form.resetValidation();
            this.newSale = { store_code: '', num_sale: '', sale_date: new Date().toISOString().substr(0, 10), register_date: '', sales_amount: '', sales_rest: '', card_type: 'Carrefour', sales_type: '', card_num: '', new_client: '', sale_return: '' };
        }, 
        searchByFilters() {
            this.menuFilters = false;
            this.loadingSales = true;
            axios
            .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/searchsale_portaltt', {
                simpleFilter: this.simpleFilter, 
                filters: this.filters, 
                storeCode: store.getters.getUser.store_code
            })
            .then(response => {
                this.salesLoyaltyCard = response.data.data;
                this.loadingSales = false;
            })
            .catch(error => {
                console.log('Login query execution error ' + error);
                this.snackbar = true;
                this.errorMessage = 'Ha ocurrido un error buscando las ventas, por favor inténtelo más tarde.';
                this.loadingSales = false;
            });
        }, 
        clearFilters() {
            this.menuFilters = false;
            this.loadingSales = true;
            this.simpleFilter = '';
            this.filters = {
                sales_date: {
                    since: '', 
                    until: ''
                }, 
                register_date: {
                    since: '', 
                    until: ''
                }, 
                sales_type: [], 
                new_client: '', 
                sale_return: ''
            };
            this.searchByFilters();
        }, 
        csvExport(fileName, items) {
            
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(_.omit(this.salesLoyaltyCard[0], 'id')).join(";")
                .replace('num_sale', 'Venta')
                .replace('sale_date', 'Fecha de la venta')
                .replace('register_date', 'Fecha de registro')
                .replace('store_code', 'Sucursal')
                .replace('sales_amount', 'Importe de venta')
                .replace('sales_rest', 'Importe resto de Productos')
                .replace('card_type', 'Tipo tarjeta')
                .replace('sales_type', 'Tipo venta')
                .replace('card_num', 'Número de tarjeta')
                .replace('sale_return', '¿Venta o devolución?')
                .replace('new_client', '¿Es cliente nuevo?'),
                ...this.salesLoyaltyCard.map(item => Object.values(_.omit(item, 'id')).join(";").replace('true', 'Si').replace('false', 'No'))
            ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", `Tarjetas-Fidelidad-${store.getters.getUser.store_code}-` + new Date().toLocaleDateString() + ".csv");
            link.click();
        }, 
        luhnAlgorithm(value) {
            if (value.length == 13) {
                // Paso 1 - INICIO
                var chartsCardNum = [...value];
                var str21 = [..."212121212121"];
                var totalSum = 0;
                for (var i = 0; i < chartsCardNum.length - 1; i++) {
                    if (i != 2) {
                        var currentMult = parseInt(chartsCardNum[i], 10) * parseInt(str21[i], 10);
                        // Paso 1 - FIN
                        // Paso 2 - INICIO
                        if (currentMult >= 10) {
                            var strMult = [...'' + currentMult];
                            var sumMult = 0;
                            for (var j = 0; j < strMult.length; j++) {
                                sumMult += parseInt(strMult[j], 10);
                            }
                            currentMult = sumMult;
                        }
                        // Paso 2 - FIN
                        // Paso 3 - INICIO
                        totalSum += currentMult;
                        // Paso 3 - FIN
                    }
                }
                // Paso 4 - INICIO
                var sumNumbers = [...'' + totalSum];
                var nextTen = 0
                if (parseInt(sumNumbers[sumNumbers.length - 1], 10) > 0) {
                    var sumToNextTen = 10 - parseInt(sumNumbers[sumNumbers.length - 1], 10);
                    nextTen = totalSum + sumToNextTen;
                } else {
                    nextTen = totalSum;
                }
                totalSum = nextTen - totalSum;
                // Paso 4 - FIN
                // Paso 5 - INICIO
                var firstControl = 0;
                if (totalSum % 2 == 0) {
                    firstControl = totalSum/2;
                } else {
                    totalSum -= 1;
                    var preControl = '1' + totalSum;
                    firstControl = parseInt(preControl, 10) / 2;
                }
                // Paso 5 - FIN
                if (parseInt(chartsCardNum[2], 10) == firstControl) {
                    return true;
                } else {
                    return false;
                }
            }
        }, 
        ean13Algorithm(value) {
            if (value.length == 13) {
                // Paso 1 - INICIO
                var chartsCardNum = [...value];
                var firstSum = 0;
                for (var i = 1; i < chartsCardNum.length - 1; i+=2) {
                    firstSum += parseInt(chartsCardNum[i], 10);
                }
                // Paso 1 - FIN
                // Paso 2 - INICIO
                firstSum = firstSum * 3;
                // Paso 2 - FIN
                // Paso 3 - INICIO
                var secondSum = 0;
                for (var j = 0; j < chartsCardNum.length - 1; j+=2) {
                    secondSum += parseInt(chartsCardNum[j], 10);
                }
                // Paso 3 - FIN
                // Paso 4 - INICIO
                var sumStep4 = firstSum + secondSum;
                // Paso 4 - FIN
                // Paso 5 - INICIO
                sumStep4 = sumStep4 % 10;
                // Paso 5 - FIN
                // Paso 6 - INICIO
                var secondControl = 0;
                if (sumStep4 != 0) {
                    secondControl = 10 - sumStep4;
                }
                // Paso 6 - FIN
                if (parseInt(chartsCardNum[chartsCardNum.length-1], 10) == secondControl) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }, 
        originId(value) {
            if (value.length == 13) {
                var allowedNumbers = new Set();
                allowedNumbers.add('9');
                allowedNumbers.add('8');
                allowedNumbers.add('5');
                var chartsCardNum = [...value];
                if (allowedNumbers.has(chartsCardNum[3])) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }, 
        passMastercard(value) {
            if (value.length == 13) {
                var chartsCardNum = [...value];
                if (chartsCardNum[3] == '5') {
                    if (chartsCardNum[4] == '0') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
    }, 
    mounted() {
        this.searchByFilters();
    }, 
    watch: {
        'newSale.sales_amount': function(nv) {
            this.newSale.sales_amount = nv.replace('.', '');
            this.$refs.form.resetValidation();
        }, 
        'newSale.sales_rest': function(nv) {
            this.newSale.sales_rest = nv.replace('.', '');
            this.$refs.form.resetValidation();
        }
    }
}
</script>
<style>
.loyalty-card {
    padding: 50px 100px;
}
.new-sale-loyalty-card, .search-sales-loyalty-card {
    margin-bottom: 50px;
}
.new-sale-loyalty-card p {
    margin-top: 25px;
}
.action-buttons {
    padding: 20px 35px !important;
}
.filter-button {
    padding: 20px 35px !important;
}
.section-title {
    border-bottom: 2px solid #C3A269;
    display: inline-block;
}
.sales-loyalty-card-table th, td {
    border-bottom: none !important;
}
.sales-loyalty-card-table tbody tr:hover {
    background-color: #F9FAFD !important;
    color: #C3A269;
}
.filters-popup {
    padding: 30px;
}
</style>