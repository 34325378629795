<template>
  <v-app>
    <NavBar />

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'
import store from '@/store/index.js';
export default {
  name: 'App',
  components: {
    NavBar
  },
  data: () => ({
    //
  })
};
</script>
