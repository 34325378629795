<template>
  <div class="login">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-img src="@/assets/afflelou-login.png" aspect-ratio="1.1"></v-img>
      </v-col>
      <v-col cols="12" md="6" class="form-container">
        <div class="form">
          <v-spacer></v-spacer>
          <h3>Iniciar sesión</h3>
          <h5>Introduce tus datos</h5>
          <br/>
          <v-spacer></v-spacer>

          <v-form
            ref="form"
            :model="validForm"
            lazy-validation>
            <v-text-field
                label="Código de tienda"
                prepend-icon="mdi-store"
                :rules="[rules.required]"
                required
                v-model="storeCode"
                autocomplete="new-password">
            </v-text-field>
            <v-text-field
                label="Usuario"
                prepend-icon="mdi-account-outline"
                :rules="[rules.required]"
                required
                v-model="userName"
                autocomplete="new-password">
            </v-text-field>
            <v-text-field
              v-model="userPass"
              prepend-icon="mdi-lock-outline"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Contraseña"
              :rules="[rules.required]"
              required
              @click:append="showPass = !showPass"
              autocomplete="new-password"
            ></v-text-field>
            <br/>
            <v-spacer></v-spacer>
            <v-btn class="action-buttons login-button" tile color="primary" dark align="center" @click="logIn">ENTRAR</v-btn>
            <v-spacer></v-spacer>
          </v-form>
        </div>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" color="error">
      {{ errorMessage }}
      <v-btn dark text
        @click="snackbar = false">
          Cerrar
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import axios from 'axios';
import crypto from 'crypto';
import UserPermissionMixinVue from '@/mixins/UserPermissionMixin.vue';
export default {
  name: 'Login',
  mixins: [UserPermissionMixinVue],
  data() {
    return {
      validForm: true, 
      storeCode: '', 
      userName: '', 
      userPass: '', 
      showPass: false, 
      snackbar: false, 
      errorMessage: '', 
      rules: {
        required: value => !!value || 'Este campo es obligatorio.'
      },
    }
  }, 
  methods: {
    logIn() {
      
      if(this.$refs.form.validate()) {
        this.storeCode = this.storeCode.toUpperCase();
        let userpass = crypto.createHash('sha256').update(this.userPass).digest('hex');
        axios
        .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/login_portaltt', {
          storeCode: this.storeCode, 
          userName: this.userName, 
          userPass: userpass
        })
        .then(response => {
          if(response.data.data) {
            let data = {
              user: response.data.data,
              valid: new Date().getTime() + 120*60000
            }
            sessionStorage.setItem('user', JSON.stringify(data));
            // Get saved data from sessionStorage
            this.$store.dispatch('logInUser', response.data.data);
            this.$router.push('/');
          }
        })
        .catch(error => {
          console.log('Login query execution error ' + error)
          this.validForm = false;
          this.snackbar = true;
          this.errorMessage = 'El usuario no existe o no está asociado a la tienda. Por favor inténtelo de nuevo.'
        });

      }
    }
  }
}
</script>
<style>
.form-container, .form {
  width: 50%;
  margin: auto;
}
.login-button {
  width: 50%;
  margin: auto;
  margin: 0% 25%;
}
</style>
