<template>
    <div class="coupon-detail">

        <v-btn class="ma-2" text color="primary" to="/coupons">
            <v-icon left>mdi-chevron-left</v-icon> Atrás
        </v-btn>

        <!-- TITLE -->
        <v-row>
            <v-col cols="12" md="10">
                <h2 class="section-title font-weight-black">Ficha cupón {{ this.currentCoupon.name }} ({{ this.currentCoupon.status }})</h2>
            </v-col>
            <v-col cols="12" md="1">
                <v-btn rounded outlined color="primary" dense class="ma-2">
                    <v-icon>mdi-printer</v-icon> IMPRIMIR
                </v-btn>
            </v-col>
            <v-col cols="12" md="1">
                <v-btn rounded outlined color="primary" dense class="ma-2">
                    <v-icon>mdi-receipt</v-icon> CONSUMIR
                </v-btn>
            </v-col>
        </v-row>

        <!-- Datos Marketing -->
        <h3>Datos Marketing</h3>
        <v-row>
            <v-col cols="12" md="12">
                <span class="text--secondary">Promoción: </span> {{ this.currentCoupon.campaign }}
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <span class="text--secondary">Tienda: </span> {{ this.currentCoupon.store_code }}
            </v-col>
            <v-col cols="12" md="3">
                <span class="text--secondary">Fecha Consumo: </span> {{ this.currentCoupon.consumed_date }}
            </v-col>
            <v-col cols="12" md="3">
                <span class="text--secondary">Fecha Creación: </span> {{ this.currentCoupon.created_date }}
            </v-col>
            <v-col cols="12" md="3">
                <span class="text--secondary">Fecha Validez: </span> {{ this.currentCoupon.validation_date }}
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <span class="text--secondary">Importe de la venta padrino: </span> {{ this.currentCoupon.sale_amount + ' €' }}
            </v-col>
        </v-row>

        <!-- Datos Padrino / Ahijado -->
        <v-row>
            <v-col cols="12" md="6">
                <!-- Datos Padrino -->
                <v-row>
                    <v-col cols="12" md="12">
                        <h3>Datos Padrino</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Datos Personales</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Nombre completo: </span> 
                        {{ this.currentCoupon.godfather_firstname + ' ' + this.currentCoupon.godfather_lastname1 + ' ' + this.currentCoupon.godfather_lastname2 }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Fecha de nacimiento: </span> {{ this.currentCoupon.godfather_birthdate }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">DNI/NIE: </span> {{ this.currentCoupon.godfather_nif }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Género: </span> {{ this.currentCoupon.godfather_gender }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Datos Contacto</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Email: </span> {{ this.currentCoupon.godfather_email }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Móvil: </span> {{ this.currentCoupon.godfather_mobile }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Dirección: </span> {{ this.currentCoupon.godfather_address }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">CP: </span> {{ this.currentCoupon.godfather_zip }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Población: </span> {{ this.currentCoupon.godfather_city }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Provincia: </span> {{ this.currentCoupon.godfather_state }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">País: </span> {{ this.currentCoupon.godfather_country }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Datos RGPD</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                    <span class="text--secondary">Firmado RGPD: </span> {{ (this.currentCoupon.godfather_rgpd == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Comunicaciones comerciales Alain Afflelou: </span> {{ (this.currentCoupon.godfather_communications == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Cesión otras ópticas: </span> {{ (this.currentCoupon.godfather_cesion == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Cesión Fundación Alain Afflelou: </span> {{ (this.currentCoupon.godfather_cesion_foundation == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <!-- Datos Ahijado -->
                <v-row>
                    <v-col cols="12" md="12">
                        <h3>Datos Ahijado</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Datos Personales</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Nombre completo: </span>
                        {{ this.godchild_fullname }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Fecha de nacimiento: </span> 
                        {{ this.currentCoupon.godchild_birthdate }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">DNI/NIE: </span> 
                        {{ this.currentCoupon.godchild_nif }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Género: </span> 
                        {{ this.currentCoupon.godchild_gender }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Datos Contacto</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Email: </span> 
                        {{ this.currentCoupon.godchild_email }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Móvil: </span> 
                        {{ this.currentCoupon.godchild_mobile }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Dirección: </span> 
                        {{ this.currentCoupon.godchild_address }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">CP: </span> 
                        {{ this.currentCoupon.godchild_zip }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Población: </span> 
                        {{ this.currentCoupon.godchild_city }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Provincia: </span> 
                        {{ this.currentCoupon.godchild_state }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">País: </span> 
                        {{ this.currentCoupon.godchild_country }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <h4>Datos RGPD</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Firmado RGPD: </span> 
                        {{ (this.currentCoupon.godchild_rgpd == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Comunicaciones comerciales Alain Afflelou: </span> 
                        {{ (this.currentCoupon.godchild_communications == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Cesión otras ópticas: </span> 
                        {{ (this.currentCoupon.godchild_cesion == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <span class="text--secondary">Cesión Fundación Alain Afflelou: </span> 
                        {{ (this.currentCoupon.godchild_cesion_foundation == 'true') ? 'Sí' : 'No' }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'CouponDetail',
    props: {
        coupon: {
            type: String
        }
    }, 
    data() {
        return {
            currentCoupon: { name: '043057001262', status: 'vigente', promotion: 'Tchin Tchin Verano 2020', created: '2MMA', consumed_date: '05/10/2020', created_date: '05/10/2020', validate_date: '05/10/2020', sales_padrino: '200', padrino_name: 'María Martínez Soldevilla', ahijado_name: 'María Martínez Soldevilla', padrino_birthdate: '05/10/1990', ahijado_birthdate: '05/10/1990', padrino_dni: '99999999R', ahijado_dni: '99999999R', padrino_gender: 'Mujer', ahijado_gender: 'Mujer', padrino_email: 'marimartinez@gmail.com', ahijado_email: 'marimartinez@gmail.com', padrino_mobile: '656478952', ahijado_mobile: '656478952', padrino_address: 'Calle Orense 11, 7º Iz', ahijado_address: 'Calle Orense 11, 7º Iz', padrino_cp: '28020', ahijado_cp: '28020', padrino_city: 'Madrid', ahijado_city: 'Madrid', padrino_state: 'Madrid', ahijado_state: 'Madrid', padrino_country: 'España', ahijado_country: 'España', padrino_rgpd_signed: 'No', ahijado_rgpd_signed: 'No', padrino_comercial_aa: 'Sí', ahijado_comercial_aa: 'Sí', padrino_other_optiques: 'Sí', ahijado_other_optiques: 'Sí', padrino_other_foundation: 'No', ahijado_other_foundation: 'No' }
        }
    }, 
    methods: {
        comsumeCoupon() {
            console.log(this.$route.query.coupon);
        }
    }, 
    created: function() {
        console.log(this.$route.query.coupon);
        if (this.$route.query.coupon) {
            axios
            .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/getCoupon_portaltt', {
                couponCode: this.$route.query.coupon
            })
            .then(response => {
                this.currentCoupon = response.data.data[0];
            })
            .catch(error => {
                console.log('GetCoupon query execution error ' + error);
                this.snackbar = true;
                this.errorMessage = 'Ha ocurrido un error obteniendo el cupón, por favor inténtelo más tarde.';
            });
        }
    }, 
    computed: {
        godchild_fullname() {
            if (this.currentCoupon.godchild_firstname) {
                return this.currentCoupon.godchild_firstname + ' ' + this.currentCoupon.godchild_lastname1 + ' ' + this.currentCoupon.godchild_lastname2;
            } else {
                return '';
            }
        }
    }, 
}
</script>
<style>
.coupon-detail {
    padding: 50px 100px;
}
.coupon-detail h3 {
    color: #C3A269;
}
</style>