<template>
  <div class="navbar">
    <nav>
        <v-app-bar  class="nav-bar" app color="#fff" v-if="isAuthenticated">

          <v-toolbar-title class="text-uppercase grey--text">
              <router-link to="/">
                <v-img :src="require('@/assets/afflelou_logo.jpg')" style="width:150px" />
              </router-link>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-toolbar-items v-if="isAuthenticated">
              <v-btn text slot="activator" color="grey-darken-3" to="/users" v-if="user.super_admin || user.store_admin">
                  <span>GESTIÓN USUARIOS PORTAL</span>
              </v-btn>
              <v-btn text slot="activator" color="grey-darken-3" to="/loyalty-card">
                  <span>TARJETAS FIDELIDAD</span>
              </v-btn>
              <!--<v-btn text slot="activator" color="grey-darken-3" to="/coupons">
                  <span>CUPONES</span>
              </v-btn>-->
          </v-toolbar-items>
          <v-spacer></v-spacer>
          
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" color="grey-darken-3">
                <span style="margin-right: 10px;">{{user.nombre + ' ' + user.apellidos}}</span>
                <v-avatar color="primary" size="48">
                  <span class="white--text headline">
                    {{user.nombre.substring(0,1).toUpperCase() + user.apellidos.substring(0,1).toUpperCase()}}
                  </span>
                </v-avatar>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="resetPasswordDialog = true">
                <v-list-item-title>Cambiar contraseña</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logOut">
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          
      </v-app-bar>
    </nav>

    <v-dialog v-model="resetPasswordDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
            Cambiar contraseña
        </v-card-title>
        <v-card-text>
          Introduzca la nueva contraseña<br/><br/>
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation>
            <v-text-field
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Crear nueva contraseña"
              :rules="[rules.required]"
              required
              v-model="newPassword"
              @click:append="showPass = !showPass">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="action-buttons" tile color="primary" dark @click="confirmReset">Cambiar contraseña</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resetPasswordConfirm" max-width="290">
      <v-card>
        <v-card-title class="headline">
            Confirmar cambio de contraseña
        </v-card-title>
        <v-card-text>
          Esta acción en irreversible, asegurese de apuntar su contraseña. Será redirigido al inicio de sesión al confirmar. <br/><br/>
          <span class="text-xs-center"><b>¿Seguro que quiere continuar?</b></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="resetPasswordConfirm = false">No</v-btn>
          <v-btn class="action-buttons" tile color="primary" dark @click="resetPassword">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="error">
      {{ errorMessage }}
      <v-btn dark text
        @click="snackbar = false">
          Cerrar
      </v-btn>
    </v-snackbar>

  </div>
</template>
<script>
import axios from 'axios'
import crypto from 'crypto'
import store from '@/store/index.js'
import UserPermissionMixinVue from '@/mixins/UserPermissionMixin.vue';
export default {
  mixins: [UserPermissionMixinVue],
  data() {
    return {
      newPassword: '', 
      validForm: true, 
      showPass: false, 
      resetPasswordDialog: false, 
      resetPasswordConfirm: false, 
      snackbar: false, 
      errorMessage: '', 
      rules: {
        required: value => !!value || 'Este campo es obligatorio.'
      }
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOutUser");
      this.$router.push("/login");
    }, 
    confirmReset() {
      if (this.$refs.form.validate()) {
        this.resetPasswordConfirm = true;
      }
    }, 
    resetPassword() {
      let userpass = crypto.createHash('sha256').update(this.newPassword).digest('hex');
      console.log(store.getters.getUser.id);
      console.log(userpass);
      axios
      .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/resetpassword_portaltt', {
          userId: store.getters.getUser.id, 
          userPass: userpass
      })
      .then(response => {
          this.resetPasswordConfirm = false;
          this.resetPasswordDialog = false;
          this.$store.dispatch("logOutUser");
          this.$router.push("/login");
      })
      .catch(error => {
          console.log('Login query execution error ' + error);
          this.snackbar = true;
          this.errorMessage = 'Ha ocurrido cambiando la contraseña, por favor inténtelo más tarde.';
      });
    }
  }
};
</script>
<style>
.nav-bar {
    padding: 0% 5%;
}
</style>