<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
            <v-btn color="primary" text @click="createUser">NUEVO USUARIO</v-btn>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="usersItems"
          :loading="usersLoading"
          :items-per-page=10
          loading-text="Cargando usuarios..."
          item-key="id">
            <template v-slot:item.action="{ item }">
              <v-icon small color="primary" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small color="primary" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="userDialog" max-width="1200px">
          <pop-up-new-user :mode="workingMode" :user="currentUser" @close="closePopup" /> 
        </v-dialog>
        
      </v-card>
    </v-container>

    <v-dialog v-model="deleteUserConfirm" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Eliminar usuario
        </v-card-title>
        <v-card-text>
          Esta acción en irreversible, el usuario será eliminar del sistema sin opción a recuperarlo. <br/><br/>
          <span class="text-xs-center"><b>¿Seguro que quiere continuar?</b></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelDelete">No</v-btn>
          <v-btn class="action-buttons" tile color="primary" dark @click="deleteUser">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import crypto from 'crypto'
import store from '@/store/index.js'
import PopUpNewUser from '@/components/PopUpNewUser.vue'
export default {
  name: 'Users',
  components: {
    PopUpNewUser
  },
  data() {
    return {
      userDialog: false, 
      usersItems: [], 
      usersLoading: false, 
      workingMode: 'create', 
      currentUser: {}, 
      deleteUserConfirm: false, 
      userIdToDelete: '', 
      headers: [
          { text: 'SOCIEDAD', value: 'society', sortable: false },
          { text: 'CÓDIGO TIENDA', value: 'store_code', sortable: false },
          { text: 'USERNAME', value: 'user_name', sortable: false },
          { text: 'NOMBRE', value: 'nombre', sortable: false },
          { text: 'APELLIDOS', value: 'apellidos', sortable: false }, 
          { text: 'ACCIONES' , align: 'center', sortable: false, value: 'action' },
      ], 
    }
  }, 
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.usersLoading = true;
      axios
      .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/usersbystore_portaltt', {
          storeCode: store.getters.getUser.store_code, 
          superadmin: store.getters.getUser.super_admin, 
      })
      .then(response => {
          this.usersItems = response.data.data;
          this.usersLoading = false;
      })
      .catch(error => {
          console.log('Login query execution error ' + error);
          this.snackbar = true;
          this.errorMessage = 'Ha ocurrido un error buscando los usuarios, por favor inténtelo más tarde.';
          this.usersLoading = false;
      });
    }, 
    createUser() {
      this.workingMode = 'create';
      this.currentUser = {};
      this.userDialog = true;
    },
    editItem(item) {
      this.workingMode = 'edit';
      this.currentUser = Object.assign({}, item);
      this.userDialog = true;
    },
    deleteItem(item) {
      this.userIdToDelete = item.id;
      this.deleteUserConfirm = true;
    },
    cancelDelete() {
      this.userIdToDelete = '', 
      this.deleteUserConfirm = false;
    }, 
    deleteUser() {
      axios
      .post('https://europe-west2-boxwood-sector-257612.cloudfunctions.net/deleteuser_portaltt', {
          userId: this.userIdToDelete
      })
      .then(response => {
        this.userIdToDelete = '', 
        this.deleteUserConfirm = false;
        this.loadUsers();
      })
      .catch(error => {
          console.log('Login query execution error ' + error);
          this.snackbar = true;
          this.errorMessage = 'Ha ocurrido un error borrando el usuario, por favor inténtelo más tarde.';
      });
    }, 
    closePopup() {
      this.userDialog = false;
      this.currentUser = null;
      this.loadUsers();
    }
  }
}
</script>

<style>
</style>