import Vue from 'vue'
import store from '@/store/index.js'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Coupons from '../views/Coupons.vue'
import Users from '../views/Users.vue'
import LoyaltyCard from '../views/LoyaltyCard.vue'
import CouponDetail from '../views/CouponDetail.vue'

function isAuth (to, from, next) {
  if (store.getters.getUser !== null) {
    //if (to == '/users' && store.getter.getUser.admin) {
    //  next(from)
    //} else {
      next();
    //}
  } else {
    next('/login')
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LoyaltyCard, 
    beforeEnter: isAuth
  },/* 
  {
    path: '/coupons',
    name: 'Coupons',
    component: Coupons, 
    beforeEnter: isAuth
  }, */
  {
    path: '/loyalty-card',
    name: 'LoyaltyCard',
    component: LoyaltyCard, 
    beforeEnter: isAuth
  },/* 
  {
    path: '/coupon-detail',
    name: 'CouponDetail',
    component: CouponDetail, 
    beforeEnter: isAuth, 
    props: {
      coupon: ''
    }
  }, */
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/users',
    name: 'Users',
    component: Users, 
    beforeEnter: isAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
