<script>
export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters.isLogged;
    },
    user() {
      return this.$store.getters.getUser;
    },

    permissionsProducts() {
      return this.user && this.user.permissions && this.user.permissions.product ? this.user.permissions.product : {}
    },
    permissionsAccounts() {
      return this.user && this.user.permissions && this.user.permissions.account ? this.user.permissions.account : {}
    },
    permissionsContacts() {
      return this.user && this.user.permissions && this.user.permissions.contact ? this.user.permissions.contact : {}
    },
    permissionsUsers() {
      return this.user && this.user.permissions && this.user.permissions.user ? this.user.permissions.user : {}
    }
  }
}
</script>