import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
    }
  },
  actions: {
    logInUser (context, user) {
      context.commit('setUser', user)
    },
    
    logOutUser (context, user) {
      context.commit('setUser', null)
    }
  },
  getters: {
    getUser (state) {
      return state.user;
    },
    isLogged (state) {
      return state.user !== null;
    }
  }
})
